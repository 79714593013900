import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFilter',
})
export class NameFilterPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    } else {
      value = value
        .split(' ')
        .map(function (item) {
          return item[0];
        })
        .join('')
        .substr(0, 2);
      return value;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginator',
})
export class PaginatorPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let searchText = args[2];
    let searchProperties = args[3];
    let paginator;
    if (searchText) {
      let items = [];
      searchProperties.forEach(
        (p) =>
          (items = items.concat(
            value.filter(
              (item) =>
                (item[p] || '').toString().toLowerCase().indexOf(searchText.toLowerCase()) != -1 &&
                items.indexOf(item) === -1
            )
          ))
      );
      value = items;
      paginator = {
        pageSize: 10,
        pageIndex: 0,
      };
    } else {
      paginator = args[0] || args[1];
    }
    if (paginator) {
      paginator.pageSize = paginator.pageSize || 10;
      return value.slice(
        paginator.pageSize * paginator.pageIndex,
        paginator.pageSize * paginator.pageIndex + paginator.pageSize
      );
    }
    return value;
  }
}

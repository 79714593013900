import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    const positive = 'Positive';
    let price = value ? parseFloat(parseFloat(value).toFixed(2)) : 0;
    if (price && args.length && args[0] === positive) {
      price = Math.abs(value);
    }
    if (price) {
      return this.priceFormat(price);
    }
    return price;
  }

  priceFormat(price: number) {
    let num: string;
    const parts = price.toFixed(2).split('.');
    num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
    if (num.includes('.')) {
      num = '$' + num;
    } else {
      num = '$' + num.concat('.00');
    }
    return num;
  }
}

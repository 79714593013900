import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from './http.service';

import { PromptComponent } from '../components/modals/prompt/prompt.component';
import { ConfirmComponent } from '../components/modals/confirm/confirm.component';
import { LoadingComponent } from '../components/modals/loading/loading.component';
import { AlertComponent } from '../components/modals/alert/alert.component';
import { PaymentPlanComponent } from '../components/modals/payment-plan/payment-plan.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PropertyManagementFeature } from '../types/property-management-feature';
import { EventsTypes } from '../core/enums/events-types.enum';
import { PhoneConversationPopupComponent } from '../components/phone-conversation-popup/phone-conversation-popup.component';
import { PhoneTranscriptionPopupData } from '../types/phone-conversations.model';
import { environment } from 'src/environments/environment';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DEFAULT_ERROR_MESSAGE } from '../const/const';
import { YamlActionsPopupComponent } from '../components/yaml-actions-popup/yaml-actions-popup.component';

@UntilDestroy()
@Injectable()
export class ToolsService {
  NS_URL = environment.NS_URL;
  currentMonth: string | number = '';
  currentYear: string | number = '';
  currentDayNumber: string | number = '';
  monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  month: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public editorConfig: AngularEditorConfig = {
    editable: true,
    outline: false,
    spellcheck: true,
    height: '0',
    minHeight: '1',
    maxHeight: '500px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    toolbarHiddenButtons: [
      ['undo', 'redo', 'strikeThrough', 'subscript', 'superscript', 'indent', 'outdent', 'heading', 'fontName'],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };
  days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  activeApp = true;
  constructor(public http: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog) {}

  public companiesChangeCallSource = new Subject<Observable<string>>();
  public companiesChange = this.companiesChangeCallSource.asObservable();

  countDown: string = '';
  toast(message: string, action: string = 'Close') {
    this.snackBar.open(message || DEFAULT_ERROR_MESSAGE, action, { duration: 5000 });
  }
  reloadApp() {
    this.activeApp = false;
    setTimeout(() => {
      this.activeApp = true;
    });
  }
  toastClose() {
    this.snackBar.dismiss();
  }

  EURIC(url, queries) {
    let str = '';
    for (let key in queries) {
      if (str != '') str += '&';
      str += key + '=' + encodeURIComponent(queries[key]);
    }
    return url + '?' + str;
  }

  public async confirm(data): Promise<boolean> {
    return new Promise((resolve: any) =>
      this.dialog
        .open(ConfirmComponent, {
          data,
          disableClose: data.disableClose,
          maxWidth: data.maxWidth,
          maxHeight: data.maxHeight,
          position: data.position,
          autoFocus: false,
          scrollStrategy: new NoopScrollStrategy(),
        })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((result) => resolve(result))
    );
  }

  public prompt(data): Promise<string> {
    return new Promise((resolve: any) =>
      this.dialog
        .open(PromptComponent, { data, disableClose: true, autoFocus: false, scrollStrategy: new NoopScrollStrategy() })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((result) => resolve(result))
    );
  }

  alert(title, message, button?) {
    this.dialog.open(AlertComponent, {
      data: { title: title, message: message, button: button },
      disableClose: true,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  loading(type: boolean, message?: string): void {
    if (type && this.dialog.openDialogs.length === 0) {
      this.dialog.open(LoadingComponent, {
        data: { message },
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy(),
        panelClass: 'loading-dialog',
      });
    } else if (!type && this.dialog.openDialogs.length) {
      this.dialog.closeAll();
    }
  }

  filterByText(arr, text, field) {
    text = text.toLowerCase();
    return arr.filter((x) => x[field].includes(text));
  }

  daysArray() {
    let array = [];
    let now = new Date();
    this.currentMonth = this.month[now.getMonth()];
    this.currentYear = now.getFullYear();
    let currentMonthDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    this.currentDayNumber = now.getDate();
    for (let i = 1; i <= currentMonthDays; i++) {
      array.push(i);
    }
    return array;
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return a && b && (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  dynamicSort(arr: any, field: any) {
    return arr.sort(function (a, b) {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    });
  }

  removeItem(arr, item) {
    return arr.filter((f) => f !== item);
  }
  getDate(date) {
    // outputs “01/03/2018”  'MM/DD/YYYY'
    date = new Date(date);
    var year = date.getFullYear();

    var month = date.getMonth() + 1;
    if (month <= 9) month = '0' + month;

    var day = date.getDate();
    if (day <= 9) day = '0' + day;

    var prettyDate = month + '/' + day + '/' + year;
    return prettyDate;
  }
  getDateFormat2(date) {
    // outputs "Monday, August 04 at 2:00 AM"
    date = new Date(date);
    let dayName = this.days[date.getDay()];
    let monthName = this.month[date.getMonth()];
    // let hours = date.getHours();
    // let ampm = hours >= 12 ? 'PM' : 'AM';
    let day = date.getDate();
    let time = date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    if (day <= 9) day = '0' + day;
    return dayName + ', ' + monthName + ' ' + day + ' at ' + time;
  }
  allowEnglishCharacters(
    key // allow english characters only
  ) {
    console.log(key);
    var allowed = 'Enter1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ :;,.?!£#$%^&*()_+-*{}@~<>&"\'';
    return allowed.includes(key);
    // return allowed.indexOf(String.fromCharCode(key)) !=-1 ;
  }
  phonePrinter(value) {
    if (!value || value == ' ') return value;
    if (value && value.includes('Mobile: ')) {
      value = value.replace('Mobile: ', '');
    }
    if (value.includes('(')) {
      return '+10 ' + value;
    }
    return value;
  }
  getMonthsDaysCount(number: number) {
    const today = new Date();
    const date2 = new Date();
    date2.setMonth(date2.getMonth() - number);
    const differenceInTime = date2.getTime() - today.getTime();
    return Math.round(Math.abs(differenceInTime / (1000 * 3600 * 24)));
  }
  getTimeDistance(startDate: Date, endDate: Date) {
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();
    return endDateTime - startDateTime;
  }

  paymentPlan(data, balance: number, tenantID: string, propertyManagementFeature?: PropertyManagementFeature) {
    return new Promise((resolve: any) =>
      this.dialog
        .open(PaymentPlanComponent, {
          data: { paymentPlan: data, balance, tenantID, propertyManagementFeature },
          disableClose: true,
          minWidth: '30%',
        })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((result) => resolve(result))
    );
  }

  scrollToBottom(id: string): void {
    const el = document.getElementById(id);
    if (el) el.scrollTop = el.scrollHeight;
  }

  public openEventsInformationPopup(data: PhoneTranscriptionPopupData): void {
    if (data.eventType === EventsTypes.PHONEBOT_REMINDER_CALL || data.eventType === EventsTypes.PHONEBOT_IVR_CALL) {
      this.dialog.open(PhoneConversationPopupComponent, {
        panelClass: 'conversation-popup',
        data: { ...data },
        width: '60%',
        maxHeight: '90vh',
        scrollStrategy: new NoopScrollStrategy(),
      });
    }
  }

  public openYamlActionsPopup(data: { actions: string[] }): void {
    this.dialog.open(YamlActionsPopupComponent, {
      panelClass: 'yaml-actions-popup',
      data: { ...data },
      width: '40%',
      maxHeight: '90vh',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal',
})
export class OrdinalPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    if (!value) {
      return 'N/A';
    }
    if (value === 1) {
      return value + 'st';
    } else if (value === 2) {
      return value + 'nd';
    } else if (value === 3) {
      return value + 'rd';
    } else {
      return value + 'th';
    }
  }
}

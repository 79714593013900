import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectSearch',
})
export class SelectSearchPipe implements PipeTransform {
  transform(value: any, searchText: string = '', props: string[] = []): any {
    if (!searchText) return false;
    let isMatched = false;
    props.forEach((prop) => (new RegExp(searchText, 'gi').test(value[prop]) ? (isMatched = true) : null));
    return !isMatched;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
enum Types {
  MINUTES = 'Minutes',
  SECONDS = 'Seconds',
  HOURS = 'Hours',
  DAYS = 'Days',
}
enum Numbers {
  THOUSANDS = 1000,
  SIXTY = 60,
  TWENTY_FOUR = 24,
  TEN = 10,
}
const ZERO = '0';
const DOUBLE_ZERO = '00';
interface Timespan {
  createdAt: Date;
  fallbackDate: Date;
}

@Pipe({
  name: 'timespan',
})
export class TimespanPipe implements PipeTransform {
  transform(value: Timespan | number, type: string, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }
    switch (type) {
      case Types.DAYS:
        if (typeof value !== 'number') {
          return this.getDaysTime(value);
        }
        return;
      case Types.MINUTES:
        if (typeof value === 'number') {
          return this.getMinutesTime(value);
        }
        return;
      default:
        return value;
    }
  }
  getDaysTime(value: Timespan) {
    const timespan = this.getTimespan(value.fallbackDate, value.createdAt);
    const days = this.getDays(timespan);
    const hours = this.getHours(timespan);
    const minutes = this.getMinutes(timespan);
    const seconds = this.getSeconds(timespan);
    const time = `${days} : ${hours} : ${minutes} : ${seconds} ${this.getDaysText(timespan)}`;
    return time;
  }
  getMinutesTime(value: number) {
    const minutes = this.getMinutes(value);
    const seconds = this.getSeconds(value);
    const time = `${minutes} : ${seconds} ${this.minutesOrSecondsText(value)} `;
    return time;
  }
  getDays(value: number) {
    const days = Math.floor(value / (Numbers.THOUSANDS * Numbers.SIXTY * Numbers.SIXTY * Numbers.TWENTY_FOUR));
    return days < Numbers.TEN ? `${ZERO}${days}` : days;
  }
  getHours(value: number) {
    const hours = Math.floor(
      (value % (Numbers.THOUSANDS * Numbers.SIXTY * Numbers.SIXTY * Numbers.TWENTY_FOUR)) /
        (Numbers.THOUSANDS * Numbers.SIXTY * Numbers.SIXTY)
    );
    return hours < Numbers.TEN ? `${ZERO}${hours}` : hours;
  }
  getMinutes(value: number) {
    const minutes = Math.floor(
      (value % (Numbers.THOUSANDS * Numbers.SIXTY * Numbers.SIXTY)) / (Numbers.THOUSANDS * Numbers.SIXTY)
    );
    return minutes < Numbers.TEN ? `${ZERO}${minutes}` : minutes;
  }
  getSeconds(value: number) {
    const seconds = Math.floor((value % (Numbers.THOUSANDS * Numbers.SIXTY)) / Numbers.THOUSANDS);
    return seconds < Numbers.TEN ? `${ZERO}${seconds}` : seconds;
  }
  minutesOrSecondsText(value: number) {
    const minutes = this.getMinutes(value);
    return minutes != DOUBLE_ZERO ? Types.MINUTES : Types.SECONDS;
  }
  getDaysText(value: number) {
    const days = this.getDays(value);
    const hours = this.getHours(value);
    const minutes = this.getMinutes(value);
    if (days != DOUBLE_ZERO) {
      return Types.DAYS;
    } else if (hours != DOUBLE_ZERO) {
      return Types.HOURS;
    } else if (minutes != DOUBLE_ZERO) {
      return Types.MINUTES;
    } else {
      return Types.SECONDS;
    }
  }
  getTimespan(startDate: Date, endDate: Date) {
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();
    return endDateTime - startDateTime;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  public transform(inputArray: string[], text: string): boolean {
    return Array.isArray(inputArray) && inputArray.includes(text);
  }
}

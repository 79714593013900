const LogsData = {
  date: 'Wed, 05/23/2021, 5:35 pm',
  supervisor: 'sv1@colleen.ai',
  timespan: '39 min',
  prospect: '12345675678',
};

enum StatusNames {
  LAST_INTERACTION = 'Last interaction',
  STATUS = 'Status',
}

enum Paths {
  HISTORY_LOG = '/main/history-log',
  ADMIN_VERIFICATION = '/main/admin-verification',
  ADMIN = 'main/insights',
  SUPERVISOR = 'main/rent-collection',
  SETTINGS = 'main/settings',
}

enum Softwares {
  ENTRATA = 'Entrata',
  YARDI = 'Yardi',
  RENT_MANAGER = 'Rent Manager',
}

enum TimespanFields {
  CREATED_AT = 'createdAt',
  SV_EMAIL = 'svEmail',
  TIMESPAN_DISTANCE = 'timespanDistance',
  WORKED_DISTANCE = 'workedDistance',
  TENANT_NAME = 'tenantName',
}

enum Roles {
  ADMIN = 'Admin',
  SUPERVISOR = 'Supervisor',
}

const ADMIN_PATHS = [
  '/main/supervisor',
  '/main/debt-collection',
  '/main/rent-collection',
  '/main/renewals',
  '/main/leasing',
  '/main/admins',
  '/main/insights',
  '/main/timespans',
  '/main/sv-learning',
  '/main/sv-answers',
  '/main/history-log',
  '/main/admin-verification',
  '/main/settings',
];
const SUPERVISORS_PATHS = [
  '/main/verification',
  '/main/debt-collection',
  '/main/rent-collection',
  '/main/renewals',
  '/main/leasing',
];
const EMPTY_STRING = '';

const Verification_Paths = ['/main/verification'];

enum LedgerFieldNames {
  NINTY_DAYS_PLUS = '90+',
  SIXTY_DAYS_PLUS = '60+',
  THIRTY_DAYS_PLUS = '30+',
  THIRTY_DAYS = '30',
  EMPTY_3 = '__EMPTY_3',
  EMPTY_4 = '__EMPTY_4',
  EMPTY_6 = '__EMPTY_6',
  EMPTY_7 = '__EMPTY_7',
  EMPTY_8 = '__EMPTY_8',
  EMPTY_9 = '__EMPTY_9',
  EMPTY_11 = '__EMPTY_11',
  TEN_RENT = 'TenRent',
  LATE_FEES = 'LateFees',
}

enum DataTypes {
  OBJECT = 'object',
  STRING = 'string',
  BOOLEAN = 'boolean',
}

const ROWS_PER_PAGE = 25;

enum OptingStatuses {
  OPT_OUT = 'optOut',
  OPT_IN = 'optIn',
  NO_INDICATION = 'noIndication',
}

const PHONE_INTERACTION = 'phone_interaction';
const EMAIL_INTERACTION = 'email_interaction';

const DEACTIVATED_INDIVIDUAL_STATUS = Object.freeze({
  status: false,
});

const PAYMENT_RECEIVED = 'Payment Received';

const ALLOWED_SMS_ATTACHMENTS_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif'];

const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

enum ColorTypes {
  ORANGE = 'orange',
  PURPLE = 'purple',
  BLUE = 'blue',
  LIGHT_BLUE = 'light-blue',
}

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again';

const PREFERED_BATHS_BEDS_NUMBERS = Array.from({ length: 20 }, (_, i) => i + 1); // 20 is the maximum number of beds/baths

export {
  LogsData,
  StatusNames,
  Paths,
  Softwares,
  TimespanFields,
  Roles,
  ADMIN_PATHS,
  SUPERVISORS_PATHS,
  LedgerFieldNames,
  DataTypes,
  ROWS_PER_PAGE,
  EMPTY_STRING,
  OptingStatuses,
  PHONE_INTERACTION,
  EMAIL_INTERACTION,
  DEACTIVATED_INDIVIDUAL_STATUS,
  Verification_Paths,
  PAYMENT_RECEIVED,
  ALLOWED_SMS_ATTACHMENTS_EXTENSIONS,
  CUSTOM_DATE_FORMAT,
  ColorTypes,
  DEFAULT_ERROR_MESSAGE,
  PREFERED_BATHS_BEDS_NUMBERS,
};

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColorTypes } from '../../../const/const';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.css'],
})
export class PromptComponent implements OnInit {
  public text = '';
  public ColorTypes = ColorTypes;

  constructor(public dialogRef: MatDialogRef<PromptComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public ngOnInit() {
    this.text = this.data.defaultText;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notIncludes',
})
export class NotIncludesPipe implements PipeTransform {
  public transform(inputArray: string[], text: string): boolean {
    return Array.isArray(inputArray) && !inputArray.includes(text);
  }
}

export enum EventsTypes {
  PHONEBOT_REMINDER_CALL = 'phonebot reminder call',
  PHONEBOT_IVR_CALL = 'phonebot IVR call',
  EMAIL = 'email',
  SMS = 'sms',
  OFFICE_DECISION = 'office-decision',
  ACTIVATION = 'activation',
  CALL = 'call',
  PAYMENT = 'payment',
  MOVED_TO_DEBT = 'Moved To Debt',
  COMPANY_ACTIVATION = 'company activation',
  SCHEDULED_TOUR_APPOINTMENT = 'scheduled tour',
  CANCELED_TOUR_APPOINTMENT = 'canceled tour',
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(value: any, searchText: string, props: any, type?: string): any {
    if (!searchText) return value;
    let items = [];
    props.forEach(
      (p) =>
        (items = items.concat(
          value.filter((item) => {
            if (type && type === 'stringArray') {
              return p.toString().toLowerCase().indexOf(searchText.toLowerCase()) != -1 && items.indexOf(item) === -1;
            }
            return (
              (item[p] || '' || item.person[p] || item.person)
                .toString()
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) != -1 && items.indexOf(item) === -1
            );
          })
        ))
    );
    return items;
  }
}

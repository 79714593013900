<h3 matDialogTitle [innerHTML]="data.title"></h3>
<mat-dialog-content>
  <p class="color-primary" [innerHTML]="data.message"></p>
  <div class="input-container">
    <label>{{ data.label }}</label>
    <input
      matInput
      [readonly]="data.isReadonly"
      [type]="data.type"
      [(ngModel)]="text"
      [placeholder]="data.placeholder"
      *ngIf="!data.multiLine && data.type === 'number'"
    />
    <input
      matInput
      [readonly]="data.isReadonly"
      [type]="data.type"
      [(ngModel)]="text"
      [placeholder]="data.placeholder"
      *ngIf="!data.multiLine && data.type !== 'number'"
    />
    <textarea
      matInput
      [readonly]="data.isReadonly"
      [type]="data.type"
      [(ngModel)]="text"
      [placeholder]="data.placeholder"
      *ngIf="data.multiLine"
      [rows]="data.rows"
    ></textarea>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button class="cancel-btn" (click)="dialogRef.close(false)">{{ data.cancel || 'No' }}</button>
  <button
    mat-button
    class="main-btn-color console-content-color"
    [class.background-purple]="data?.colorType === ColorTypes.PURPLE"
    [class.background-blue]="data?.colorType === ColorTypes.BLUE"
    [class.background-light-blue]="data?.colorType === ColorTypes.LIGHT_BLUE"
    (click)="dialogRef.close(text)"
  >
    {{ data.ok || 'Yes' }}
  </button>
</mat-dialog-actions>

<div class="row d-flex justify-content-end">
  <div class="col text-end">
    <i class="fas fa-times console-icon-color cursor-pointer" (click)="dialogRef.close(false)"></i>
  </div>
</div>
<h3 *ngIf="data.title" matDialogTitle class="text-center" [innerHTML]="data.title"></h3>
<mat-dialog-content>
  <p class="color-primary text-center font-size-14px" [innerHTML]="data.message"></p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button class="cancel-btn" (click)="dialogRef.close(false)">{{ data.no || 'No' }}</button>
  <button
    mat-button
    class="main-btn-color console-content-color"
    [class.background-purple]="data?.colorType === ColorTypes.PURPLE"
    [class.background-blue]="data?.colorType === ColorTypes.BLUE"
    [class.background-light-blue]="data?.colorType === ColorTypes.LIGHT_BLUE"
    (click)="dialogRef.close(true)"
  >
    {{ data.yes || 'Yes' }}
  </button>
</mat-dialog-actions>

import {EventsTypes} from "../core/enums/events-types.enum";
import {PropertyManagementFeature} from "./property-management-feature";

export enum MessageDirection {
  INCOMING = 'Incoming',
  OUTGOING = 'Outgoing',
}

export interface PhoneTranscriptionPopupData {
  propertyManagementFeature: PropertyManagementFeature;
  messages: PhonebotMessage[];
  eventType: EventsTypes;
  conversationID: string;
  fullName: string;
  date: Date;
}

export interface PhonebotMessage {
  text: string;
  time: Date;
  messageDirection: MessageDirection;
}

import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { AlphabeticCountriesCodes } from '../core/enums/alphabetic-countries-codes.enum';

@Pipe({
  name: 'phonePrinter',
})
export class PhonePrinterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const PNF = PhoneNumberFormat;
      const number = phoneUtil.parseAndKeepRawInput(value, AlphabeticCountriesCodes.US);
      return phoneUtil.format(number, PNF.NATIONAL);
    }
  }
}

<div class="phone-conversation-container" [class.debt]="data.propertyManagementFeature === PropertyManagementFeature.DEBT_COLLECTION" [class.renewals]="data.propertyManagementFeature === PropertyManagementFeature.LEASE_RENEWALS">
  <button (click)="dialogRef.close(false)" class="close-button" matRipple>
    <img src="./assets/images/close-icon.svg"/>
  </button>
  <div *ngIf="data.messages" class="conversation">
    <div class="header">
      <h1 class="title">Phone conversation</h1>
      <div class="subtitle">Transcript of the audio call</div>
    </div>
    <div class="body">
      <div class="conversation-details">
        <div class="icon">
          <img src="assets/images/call-orange.svg" *ngIf="data.propertyManagementFeature === PropertyManagementFeature.RENT_COLLECTION"/>
          <img src="assets/images/call-purple.svg" *ngIf="data.propertyManagementFeature === PropertyManagementFeature.DEBT_COLLECTION"/>
          <img src="assets/images/call-blue.svg" *ngIf="data.propertyManagementFeature === PropertyManagementFeature.LEASE_RENEWALS"/>
        </div>
        <div class="details">
          <div class="name">{{ data.fullName }}</div>
          <div class="event-type">
            Voice - {{ data.eventType === eventsTypes.PHONEBOT_IVR_CALL ? 'IVR call' : 'Reminder call' }}
          </div>
        </div>
        <div class="spacer"></div>
        <div class="time">{{ data.date | date: 'MMM d, y, h:mm a' }}</div>
      </div>
      <div class="divider"></div>
      <div class="messages">
        <div class="message" *ngFor="let conversation of data.messages" [class.outgoing]="conversation.messageDirection === messageDirection.OUTGOING">
          <div class="name">
            {{ conversation.messageDirection === messageDirection.INCOMING ? 'Resident' : 'Colleen' }}
          </div>
          <div class="text">
            <q>{{ conversation.text }}</q>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { compressToEncodedURIComponent } from 'lz-string';

export const getUrlCompressedParams = (url: string): Record<string, string> => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const paramObject = {};

  for (const [key, value] of params.entries()) {
    paramObject[key] = compressToEncodedURIComponent(value);
  }

  return paramObject;
};

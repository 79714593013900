import { Component } from '@angular/core';
import { ToolsService } from './providers/tools.service';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { AppService } from './providers/app.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(public tools: ToolsService, private appService: AppService) {
    initializeApp(environment.FIREBASE_CREDENTIAL);
    this.appService.initConfig();
  }
  title = 'suppervisor';
}

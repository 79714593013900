import { Companies, Company, Organization, OrganizationsAndCompanies } from '../types';

export function getSortedOrganizationsAndMap(companies: Companies[]): {
  sortedOrganizations: Organization[];
  organizationMap: { [organizationID: string]: Organization };
} {
  const organizationMap: { [organizationID: string]: Organization } = {};
  for (const company of companies) {
    const organizationID = company.organizationID;
    if (!organizationMap[organizationID]) {
      organizationMap[organizationID] = {
        name: company.organization_name,
        organizationID,
        companies: [company],
      };
    } else {
      organizationMap[organizationID].companies.push(company);
    }
  }
  const organizations = Object.values(organizationMap);
  const sortedOrganizations = sortCompaniesByCompanyName(organizations);
  return { sortedOrganizations, organizationMap };
}

function sortCompaniesByCompanyName(organizations: Organization[]): Organization[] {
  return organizations.map((organization) => {
    return {
      ...organization,
      companies: organization.companies.sort((a, b) => a.company_name.localeCompare(b.company_name)),
    };
  });
}

export function flattenOrganizations(organizations: Organization[]): OrganizationsAndCompanies[] {
  const flattenedArray: OrganizationsAndCompanies[] = [];

  organizations.forEach((org) => {
    // Add the organization part
    flattenedArray.push({
      name: org.name,
      organizationID: org.organizationID,
    });

    // Add each company part
    org.companies.forEach((comp) => {
      flattenedArray.push({
        company_name: comp.company_name,
        organization_name: comp.organization_name,
        companyID: comp.companyID,
        organizationID: comp.organizationID,
      });
    });
  });

  return flattenedArray;
}

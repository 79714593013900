import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToolsService } from '../providers/tools.service';

@Injectable()
export class AccountGuard implements CanActivate {
  constructor(private router: Router, public tools: ToolsService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const auth = !!localStorage.getItem('c-userID');
    const consoleType = localStorage.getItem('consoleType');
    if (auth && consoleType) {
      if (consoleType === 'Admin') {
        this.router.navigate(['main/supervisor']);
      } else if (consoleType == 'Supervisor') {
        this.router.navigate(['main/rent-collection']);
      }
      return false;
    } else {
      return true;
    }
  }
}

import { Injectable } from '@angular/core';
import { EnvConfig } from '../types';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public ENV_CONFIG: EnvConfig = {} as EnvConfig;

  constructor(private remoteConfig: AngularFireRemoteConfig) {}

  public async initConfig(): Promise<void> {
    if (!Object.keys(this.ENV_CONFIG).length) {
      await this.remoteConfig.fetchAndActivate();
      const params = await this.remoteConfig.getAll();
      for (const key in params) {
        this.ENV_CONFIG[key] = params[key].asString();
      }
    }
  }
}
